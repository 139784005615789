// Entry point for the build script in your package.json
import './add_jquery'
import "@hotwired/turbo-rails"
import "./controllers/application"

import * as bootstrap from "bootstrap"  

console.log('application');


